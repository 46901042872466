<template>
  <v-chart autoresize ref="trendChart" />
</template>
<script>
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { ScatterChart } from 'echarts/charts';
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  ScatterChart,
  DataZoomComponent,
]);
export default {
  name: 'BloodSugarTrendChart',
  components: {
    VChart,
  },
  props: {
    dataChart: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      trendScatterOption: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        tooltip: {
          shared: true,
          show: true,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            symbolSize: 20,
            data: [],
            type: 'scatter',
          },
        ],
      },
    };
  },
  watch: {
    dataChart: {
      handler(val) {
        if (!val) return;
        this.generateChart();
      },
      deep: true,
    },
  },
  methods: {
    generateChart() {
      this.$refs.trendChart?.clear();
      let scatterChart = this.dataChart.trendItems.items
        .map((el) => {
          return el.subTrends.map((e) => ({
            name: e.type,
            value: [
              this.$moment(el.date * 1000).format('DD/MM/YYYY'),
              e.glucose,
            ],
            timeFrame: e.timeFrameName,
            itemStyle: {
              color: e.color,
            },
          }));
        })
        .reduce((a, b) => a.concat(b), []);

      this.scatterChartSeries = scatterChart;
      this.trendScatterOption.series[0].data = scatterChart;
      this.$refs.trendChart?.setOption(this.trendScatterOption, true);
    },
  },
};
</script>
<style lang=""></style>
